.page {

  .cover {
    margin-bottom: 3rem;
  }

  hr {
    margin: 3rem auto;

    border-bottom-style: dashed;
  }

  .hl-image {
    @include breakpoint(large) {
      img {
        min-width: 140%;
      }
    }
  }

  .order-cta {
    margin: 3rem auto;

    a {
      margin-bottom: 0;
    }
  }
}

.archives .post.mini {
  h2 {
    font-size: 1.2rem;
  }

  time {
    display: block;
    font-size: 1rem;
  }
}
