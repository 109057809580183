.lang-switcher {

  .icon {
    height: 18px;
    width: 18px;

    display: inline-block;

    vertical-align: middle;
  }

  .menu {
    min-width: 150px;

    font-size: .8rem;
  }

  .lang-link {
    &:hover,
    &.active {
      text-decoration: underline;
    }
  }
}
