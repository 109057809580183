$creature-bg: #162b38;

.creatures {

  @include reverse-links;

  padding-top: 20px;
  padding-bottom: 20px;

  color: $white;
  background-color: $creature-bg;


  figure {
    margin: 0 0 20px;

    img {
      border-radius: 50%;
    }
  }

  .cta {
    @include call-to-action(clear, hollow);
  }
}
