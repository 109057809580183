@charset "utf-8";

$baseurl: '';

@font-face {
  font-family: 'SofiaPro';
  src: url('#{$baseurl}/assets/fonts/sofiapro/SofiaProRegular-webfont.eot');
  src: url('#{$baseurl}/assets/fonts/sofiapro/SofiaProRegular-webfont.eot?#iefix') format('embedded-opentype'),
       url('#{$baseurl}/assets/fonts/sofiapro/SofiaProRegular-webfont.woff2') format('woff2'),
       url('#{$baseurl}/assets/fonts/sofiapro/SofiaProRegular-webfont.woff') format('woff'),
       url('#{$baseurl}/assets/fonts/sofiapro/SofiaProRegular-webfont.ttf') format('truetype'),
       url('#{$baseurl}/assets/fonts/sofiapro/SofiaProRegular-webfont.svg#sofia_pro_regularregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SofiaPro';
  src: url('#{$baseurl}/assets/fonts/sofiapro/SofiaProBold-webfont.eot');
  src: url('#{$baseurl}/assets/fonts/sofiapro/SofiaProBold-webfont.eot?#iefix') format('embedded-opentype'),
       url('#{$baseurl}/assets/fonts/sofiapro/SofiaProBold-webfont.woff2') format('woff2'),
       url('#{$baseurl}/assets/fonts/sofiapro/SofiaProBold-webfont.woff') format('woff'),
       url('#{$baseurl}/assets/fonts/sofiapro/SofiaProBold-webfont.ttf') format('truetype'),
       url('#{$baseurl}/assets/fonts/sofiapro/SofiaProBold-webfont.svg#sofia_probold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@import "settings";
@import "foundation";

@include foundation-everything;

@import "layout";
